import { render, staticRenderFns } from "./supportFooter.vue?vue&type=template&id=461d7432&scoped=true&"
import script from "./supportFooter.vue?vue&type=script&lang=js&"
export * from "./supportFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461d7432",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBadge,VHover,VIcon})
