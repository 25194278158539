<template>

  <div :style="`background : ${wsLIGHTCARD}`"
     class="d-flex justify-center pa-4"
     style="box-shadow: 0 -3px 10px 0 rgba(154, 178, 196, 0.20); z-index: 1"
>
    <div style="width: 300px" class="d-flex justify-space-between align-center">
      <v-hover  v-for="(item,i) in items" :key="i" #default="{hover}">
        <div
            @click="$emit('input' , item.value)"
            class="pointer"
        >
          <div class="d-flex justify-center">
            <v-badge
                :content="getBadgeValue(item.value)"
                :value="getBadgeValue(item.value)"
                :color="wsATTENTION"
                overlap
                dot
            >
            <v-icon :color="hover || item.value === value ? wsATTENTION : wsACCENT">{{  item.icon  }}</v-icon>
            </v-badge>
          </div>
          <h6 class="text-center mt-1" :class="hover || item.value === value ? 'wsATTENTION' : 'wsACCENT'">{{ item.text }}</h6>
        </div>
      </v-hover>
    </div>




</div>
</template>

<script>
export default {
  name: "supportFooter",
  props : {
    value : {
      type : String
    },
    items : {
      type : Array,
      default() { return [] }
    }
  },
  methods : {
    getBadgeValue(type) {

      if ( type === 'chat' ) {
        return this.$store.state.support.chatMessagesArray.length
      }

      return 0

    }
  }
}
</script>

<style scoped>

</style>